/**
 * Parses locales provided from browser through `accept-language` header.
 *
 * @param input - Accept-Language header value.
 * @return An array of locale codes. Priority determined by order in array.
 */
export function parseAcceptLanguage(input: string): string[] {
  // Example input: en-US,en;q=0.9,nb;q=0.8,no;q=0.7
  // Contains tags separated by comma.
  // Each tag consists of locale code (2-3 letter language code) and optionally country code
  // after dash. Tag can also contain score after semicolon, that is assumed to match order
  // so it's not explicitly used.
  return input.split(',').map((tag) => tag.split(';')[0]);
}

/**
 * Find the browser locale
 *
 * @param locales - The target {@link LocaleObject | locale} list
 * @param browserLocales - The locale code list that is used in browser
 * @param options - The options for {@link findBrowserLocale} function
 *
 * @returns The matched the locale code
 */
export function findBrowserLocale(
  locales: string[],
  browserLocales: string[],
  {
    matcher = DefaultBrowserLocaleMatcher,
    comparer = DefaultBrowerLocaleComparer,
  }: FindBrowserLocaleOptions = {}
): string | '' {
  // finding!
  const matchedLocales = matcher(locales, browserLocales);

  // sort!
  if (matchedLocales.length > 1) {
    matchedLocales.sort(comparer);
  }

  return matchedLocales.length ? matchedLocales[0].code : '';
}

export function getLocalesRegex(localeCodes: string[]) {
  return new RegExp(`^/(${localeCodes.join('|')})(?:/|$)`, 'i');
}

/**
 * The browser locale info
 *
 * @remarks
 * This type is used by {@link FindBrowserLocaleOptions#sorter | sorter} in {@link findBrowserLocale} function
 */
export interface BrowserLocale {
  /**
   * The locale code, such as BCP 47 (e.g `en-US`), or `ja`
   */
  code: string;
  /**
   * The score number
   *
   * @remarks
   * The score number that is used by `sorter` of {@link FindBrowserLocaleOptions}
   */
  score: number;
}

/**
 * The target locale info
 *
 * @remarks
 * This type is used by {@link BrowserLocaleMatcher} first argument
 */
export type TargetLocale = Required<String>;

/**
 * The browser locale matcher
 *
 * @remarks
 * This matcher is used by {@link findBrowserLocale} function
 *
 * @param locales - The target {@link LocaleObject | locale} list
 * @param browserLocales - The locale code list that is used in browser
 *
 * @returns The matched {@link BrowserLocale | locale info}
 */
export type BrowserLocaleMatcher = (
  locales: string[],
  browserLocales: string[]
) => BrowserLocale[];

/**
 * The options for {@link findBrowserLocale} function
 */
export interface FindBrowserLocaleOptions {
  matcher?: BrowserLocaleMatcher;
  comparer?: (a: BrowserLocale, b: BrowserLocale) => number;
}

function matchBrowserLocale(
  locales: string[],
  browserLocales: string[]
): BrowserLocale[] {
  const matchedLocales = [] as BrowserLocale[];

  // first pass: match exact locale.
  for (const [index, browserCode] of browserLocales.entries()) {
    const matchedLocale = locales.find(
      (l) => l.toLowerCase() === browserCode.toLowerCase()
    );
    if (matchedLocale) {
      matchedLocales.push({
        code: matchedLocale,
        score: 1 - index / browserLocales.length,
      });
      break;
    }
  }

  // second pass: match only locale code part of the browser locale (not including country).
  for (const [index, browserCode] of browserLocales.entries()) {
    const languageCode = browserCode.split('-')[0].toLowerCase();
    const matchedLocale = locales.find(
      (l) => l.split('-')[0].toLowerCase() === languageCode
    );
    if (matchedLocale) {
      // deduct a thousandth for being non-exact match.
      matchedLocales.push({
        code: matchedLocale,
        score: 0.999 - index / browserLocales.length,
      });
      break;
    }
  }

  return matchedLocales;
}

/**
 * The default browser locale matcher
 */
export const DefaultBrowserLocaleMatcher = matchBrowserLocale;

function compareBrowserLocale(a: BrowserLocale, b: BrowserLocale): number {
  if (a.score === b.score) {
    // if scores are equal then pick more specific (longer) code.
    return b.code.length - a.code.length;
  }
  return b.score - a.score;
}

/**
 * The default browser locale comparer
 */
export const DefaultBrowerLocaleComparer = compareBrowserLocale;

<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="text-theme-secondary"
                          :height="10" />
    <ErrorView />
  </NuxtLayout>
</template>
<script setup lang="ts">
const platformSettings = usePlatformSettings();
platformSettings.setup();
const platformName = platformSettings.platformName;
const locale = platformSettings.locale;

const error = useError();

import bikeableMascot from '~/assets/bikeable-mascot-sad.svg';
import walkableMascot from '~/assets/walkable-mascot-sad.svg';

const mascotUrl = computed(() => {
  return usePlatformSettings().platformName.value === 'bikeable'
    ? bikeableMascot
    : walkableMascot;
});
</script>

